<template>
  <div>
    <v-container fluid class="py-8">
      <Notify></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{
                  $t("statistics.statistics")
                }}</v-toolbar-title>
              </v-app-bar>
              <Table
                :loading="loading"
                @action="action"
                :btns="btns"
                :headers="header"
                :items="statistics"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
      <Modal :data="modal_data"></Modal>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Notify from "../Components/New/Notify.vue";
import Modal from "../Components/New/Modal.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "statistics-Page",
  components: {
    Table,
    Notify,
    Modal,
  },
  data() {
    return {
      modal_data: {
        size: "500px",
        title: "",
      },
      loading: true,
      btns: [
        {
          type: "icon",
          text: "edit",
          color: "bg-gradient-success",
          icon: "mdi-pencil",
          permission: "cms-statistic-update",
        },
      ],
      header: [
        {
          text: this.$i18n.t("statistics.description"),
          value: "description",
          align: "center",
        },
        {
          text: this.$i18n.t("statistics.value"),
          value: "value",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
      form_data: {
        value: "",
      },
      form_style: [
        {
          col: "12",
          type: "text",
          label: this.$i18n.t("statistics.value"),
          error: null,
          value_text: "value",
        },
      ],
    };
  },
  computed: {
    ...mapState(["statistics"]),
  },
  methods: {
    ...mapActions(["getstatistics"]),
    get_statistics() {
      this.getstatistics().then(
        () => {
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action(id, name, item) {
      if (name == "edit") {
        this.edit_statistics(item);
      }
    },

    edit_statistics(item) {
      this.$store.commit("form/SET_DIALOG", true);
      this.modal_data.title =
        this.$i18n.t("statistics.Edit Statistic") + " # " + item.description;
      this.$store.dispatch("form/setData", this.form_style);
      this.form_data.value = item.value;
      this.$store.dispatch("form/setFormData", this.form_data);
      this.$store.commit("form/INFO_CALL", {
        name: "cms/editStatistic",
        id: item.id,
      });
    },
  },
  mounted() {
    this.get_statistics();
    document.title = this.$i18n.t("statistics.statistics");
  },
};
</script>
